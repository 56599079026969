@use "./styles/partials/variables" as *;
@use "./styles/partials/mixins" as *;

.App {
  padding-top: 7rem;

  @include tablet-design {
    padding-top: 6rem;
  }

  .toast {
    background-color: $TechPrimaryDark;
    color: $TechWhite;
  }
}
