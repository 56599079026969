@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.adminLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $TechPrimaryPale;
  min-height: 50rem;

  @include tablet-design {
    min-height: 45rem;
  }

  &__header {
    color: $TechPrimaryDark;
    margin-bottom: 4rem;

    @include mobile-design {
      font-size: 3rem;
      text-align: center;
      max-width: 90%;
    }
  }

  .adminForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__label {
      width: 100%;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
    }

    &__input {
      margin-bottom: 2rem;
      border: none;
      padding: 1rem;
      font-size: 1.6rem;
      border-radius: 8px;
      width: 40rem;

      @include mobile-design {
        width: 30rem;
      }

      &:focus {
        outline: 2px solid $TechPrimary;
      }
    }
  }
}
