@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.adminHelp {
  display: flex;
  max-width: 140rem;
  margin: 0 auto;

  &__nav {
    background-color: $TechGrayDark;
    color: $TechWhite;
    min-height: 100%;
    width: 30rem;
    position: relative;

    @include mobile-design {
      display: none;
    }

    .adminHelp__list {
      position: sticky;
      top: 50%;
      transform: translateY(-50%);
      list-style: none;
      padding-left: 1rem;

      .adminHelp__item {
        margin: 1rem 0;
        border: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid $TechWhite;
        }
      }
    }
  }

  &__section {
    text-align: center;
    font-size: 4.5rem;
    margin: 2rem 0 1rem;
  }

  &__article {
    width: 90%;
    padding: 0 4rem;
    margin-bottom: 4rem;

    .adminHelp__title {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    .adminHelp__ttn {
      margin-bottom: 1rem;
      font-weight: bold;
    }

    .adminHelp__steps {
      list-style-position: inside;

      .adminHelp__step {
        margin-bottom: 1rem;
      }

      &--special {
        list-style: inside;
        padding-left: 2rem;

        .adminHelp__step--special {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
