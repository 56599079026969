@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.givePage {
  background-color: $TechPrimaryDark;
  color: $TechWhite;
  padding-bottom: 3rem;

  @include mobile-design {
    padding-bottom: 1rem;
  }

  &__header {
    color: $TechWhite;
    margin: 3rem auto 4rem;
    width: fit-content;

    @include mobile-design {
      font-size: 3.2rem;
      margin: 2rem auto 1rem;
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    width: 85%;
    max-width: 104rem;

    @include tablet-design {
      width: 100%;
      max-width: 87rem;
      padding: 0 2rem;
    }

    @include mobile-design {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 60rem;
    }

    .givePage__option {
      width: 30%;
      min-width: 26.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      @include tablet-design {
        min-width: 22rem;
      }

      @include mobile-design {
        width: 100%;
        max-width: 38rem;
        margin: 2rem 0 3rem;
      }

      .option__iconWrapper {
        height: 10rem;
        width: fit-content;

        @include tablet-design {
          height: 8rem;
        }

        .option__img {
          width: auto;
          height: 10rem;

          @include tablet-design {
            height: 8rem;
          }
        }
      }

      .option__title {
        color: $TechWhite;
        font-family: NotoSerif;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 3rem;
        margin: 2rem 0;

        @include tablet-design {
          width: 20rem;
        }

        @include mobile-design {
          width: fit-content;
          font-size: 2.5rem;
        }
      }

      .option__desc {
        margin-bottom: 2rem;
      }

      .option__buttonWrapper {
        height: 11rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile-design {
          height: fit-content;
        }

        .option__button--give {
          height: fit-content;
          min-width: 10rem;
          width: fit-content;
          padding: 1.2rem 2rem;
          background-color: $TechPrimary;
          color: $TechWhite;
          border-radius: 10px;
          font-family: Cinzel;
          text-transform: capitalize;
          font-kerning: none;
          letter-spacing: 2px;
          border: 2px solid transparent;
          font-size: 1.3rem;

          @include mobile-design {
            color: $TechWhite;
            font-family: CinzelBold;
          }

          &:hover {
            background-color: $TechPrimaryDark;
            color: $TechWhite;
            border-color: $TechWhite;
          }
        }
      }

      .option__link {
        height: fit-content;
        margin: 0.5rem;

        .option__appStore {
          height: auto;
          width: 16rem;
        }

        .option__playStore {
          height: auto;
          width: 16rem;
        }
      }
    }
  }
}
