@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.videoPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__hero {
    background-color: $TechPrimary;
    height: 20rem;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    overflow: hidden;

    @include mobile-design {
      height: 15rem;
    }

    .videoPage__img {
      width: 100%;
      max-width: initial;
      max-height: initial;
    }
  }

  &__back {
    width: 85%;
    max-width: 110rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem auto;

    @include mobile-design {
      width: 95%;
      max-width: 60rem;
    }

    .back__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $TechPrimary;
      font-weight: bold;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: all 200ms ease-in-out;

      &:hover {
        border-color: $TechPrimary;
      }

      .fa-chevron-left {
        margin-right: 0.5rem;
        color: $TechPrimary;
      }
    }
  }

  &__audioWrapper {
    width: 85%;
    max-width: 110rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .videoPage__audio {
      width: 100%;
      max-width: 60rem;
    }
  }

  &__videoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 110rem;
    height: auto;
    aspect-ratio: 16/9;
    border-radius: 12px;
    overflow: hidden;

    @include mobile-design {
      width: 95%;
      max-width: 60rem;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 85%;
    max-width: 110rem;
    margin: 2rem 0;

    @include mobile-design {
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      width: 90%;
      max-width: 60rem;
    }

    .videoPage__sermonInfo {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 50%;

      @include mobile-design {
        width: 100%;
      }

      .videoPage__title {
        text-transform: capitalize;
        font-family: Cinzel;
        color: $TechPrimary;
        font-size: 2rem;
        margin: 1rem 0;

        @include mobile-design {
          font-family: CinzelBold;
          margin: 0;
        }
      }

      .videoPage__ref {
        color: $TechPrimary;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;

        @include mobile-design {
          margin: 1rem 0;
        }
      }
    }

    .videoPage__speakDate {
      @extend .videoPage__sermonInfo;
      align-items: flex-end;

      @include mobile-design {
        align-items: flex-start;
      }

      .videoPage__speaker {
        @extend .videoPage__title;

        @include mobile-design {
          font-size: 1.5rem;
          text-transform: lowercase;
          letter-spacing: 1px;
        }
      }

      .videoPage__date {
        @extend .videoPage__ref;

        @include mobile-design {
          display: none;
        }
      }
    }

    .videoPage__desc {
      width: 100%;
      margin: 2rem 0 1.6rem;

      @include mobile-design {
        margin: 2rem 0;
      }
    }

    .videoPage__dateDown {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      @include mobile-design {
        justify-content: space-between;
      }

      .videoPage__date--mobile {
        display: none;
        color: $TechPrimary;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;

        @include mobile-design {
          display: inherit;
        }
      }

      .videoPage__download {
        color: $TechPrimaryLight;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
}

.videoPageError {
  text-align: center;

  &__header {
    margin: 3rem 0;
  }

  &__text {
    width: 75%;
    max-width: 90rem;
    margin: 3rem auto;
    text-transform: initial;
    font-family: CinzelBold;
    letter-spacing: 2px;
  }
}
