@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.adminHome {
  position: relative;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $TechPrimaryPale;

  @include tablet-design {
    padding: 0 2rem;
  }

  @include mobile-design {
    flex-direction: column;
    padding: 0;
  }

  &__warning {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: $TechPrimaryDark;
    color: $TechWhite;
    padding: 2rem;

    @include mobile-design {
      display: flex;
    }

    .adminHome__disclose {
      max-width: 90%;
      font-size: 1.8rem;
      text-align: center;
    }
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 2rem;

    @include tablet-design {
      width: 38rem;
      flex-grow: 1;
      max-width: 50rem;
    }

    @include mobile-design {
      width: 100%;
      margin: 0;
    }

    .adminHome__card {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $TechWhite;
      border-radius: 12px;
      overflow: hidden;
      width: 50rem;
      height: 15rem;
      transition: all ease-in-out 50ms;

      @include tablet-design {
        width: 100%;
        max-width: 50rem;
      }

      @include mobile-design {
        width: 95%;
        max-width: 40rem;
        height: 12rem;
      }

      &:hover {
        height: 17rem;
        width: 52rem;

        @include tablet-design {
          width: calc(100% + 2rem);
        }

        @include mobile-design {
          width: 95%;
          height: 12rem;
        }
      }

      &--top {
        margin: 1rem 2rem 3rem;

        @include mobile-design {
          margin: 1rem;
        }

        &:hover {
          margin: 1rem 0 1rem;

          @include mobile-design {
            margin: 1rem;
          }
        }
      }

      &--bottom {
        margin: 3rem 2rem 1rem;

        @include mobile-design {
          margin: 1rem;
        }

        &:hover {
          margin: 1rem 0 1rem;

          @include mobile-design {
            margin: 1rem;
          }
        }
      }

      .adminHome__text {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        .adminHome__manage {
          color: $TechGrayDark;
          font-family: NotoSerif;
          text-transform: uppercase;
          font-size: 1.6rem;

          @include mobile-design {
            font-size: 1.5rem;
          }
        }

        .adminHome__label {
          font-size: 3rem;
          margin-top: 1rem;

          @include mobile-design {
            font-size: 2rem;
          }
        }
      }

      .adminHome__image-wrapper {
        width: 24rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;

        @include tablet-design {
          width: 20rem;
        }

        @include mobile-design {
          width: 15rem;
          flex-grow: 1;
        }

        .adminHome__image {
          width: 100%;
          max-width: none;
          max-height: none;
          height: 17rem;

          @include mobile-design {
            height: 12rem;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: $TechWhite;
    height: 38rem;
    width: 40rem;
    border-radius: 12px;
    padding: 3rem 0;

    @include tablet-design {
      width: 26rem;
      flex-grow: 1;
      max-width: 40rem;
    }

    @include mobile-design {
      width: 100%;
      border-radius: 0;

      @media (min-width: 400px) {
        border-radius: 12px;
      }
    }

    .adminHome__how-to {
      text-align: center;
      font-size: 3rem;
      max-width: 90%;
    }

    .adminHome__help-card {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30rem;
      height: 10rem;
      border-radius: 12px;
      box-shadow: 0px 4px 2.3px rgba(0, 0, 0, 0.09);
      transition: all ease-in-out 30ms;

      @include tablet-design {
        width: 24rem;
      }

      @include mobile-design {
        width: 30rem;
      }

      &:hover {
        box-shadow: 2px 4px 2.3px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
