@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contactPage {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 110rem;
    margin: 1rem auto;

    @include tablet-design {
      width: 100%;
      padding: 0 2rem;
      margin: 0 auto;
      max-width: 87rem;
    }

    @include mobile-design {
      flex-direction: column;
      max-width: 60rem;
    }

    .contactPage__content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      min-width: 30rem;
      width: 40%;
      margin-right: 4rem;
      flex-grow: 1;

      @include tablet-design {
        margin-right: 2rem;
      }

      @include mobile-design {
        margin: 0;
        align-items: center;
        text-align: center;
        width: 100%;
        min-width: initial;
        flex-grow: initial;
        padding-top: 2rem;

        .contactPage__header {
          font-size: 3rem;
        }
      }

      .contactPage__text {
        margin: 2rem 0 4rem;
        max-width: 29rem;

        @include mobile-design {
          margin: 1rem 0;
          max-width: 40rem;
        }

        .contactPage__phrase {
          margin-bottom: 2rem;
        }

        .contactPage__phone {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: $TechPrimary;

          @include mobile-design {
            justify-content: center;
            margin-bottom: 2rem;
          }

          .fa-phone {
            margin-right: 1rem;
          }

          .contactPage__number {
            font-size: 1.8rem;
          }
        }
      }

      .contactPage__socials {
        .socials__header {
          font-size: 2.4rem;
          color: $TechPrimaryDark;
        }

        .socials__social {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-transform: uppercase;
          font-size: 1.3rem;
          width: 30.2rem;
          margin: 1.5rem 0;
          color: $TechPrimary;

          @include mobile-design {
            font-size: 1.2rem;
            margin: 1.5rem auto 0;
            width: 28.6rem;

            @media (min-width: 470px) and (max-width: $mobile-breakpoint) {
              font-size: 1.4rem;
            }
          }

          .fa-youtube,
          .fa-facebook {
            color: $TechPrimary;
            margin-right: 1rem;

            @include mobile-design {
              font-size: 2.4rem;

              @media (min-width: 470px) and (max-width: $mobile-breakpoint) {
                font-size: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
