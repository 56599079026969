@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 80vh;
  aspect-ratio: 16/9;
  min-height: 30rem;
  max-height: 65rem;
  max-width: 100%;
  margin: 0 auto;

  @include desktopXL-design {
    max-height: 70rem;
  }

  @include tablet-design {
    height: auto;
    width: 100%;
    min-width: initial;
    max-width: 85rem;
  }

  @include mobile-design {
    min-height: initial;
  }

  &__link:hover {
    filter: brightness(85%);
  }

  &__slide {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--hidden {
      display: none;
    }
  }

  &__arrows {
    position: absolute;
    filter: drop-shadow(0px 0px 2px #00000060);
    width: fit-content;
    height: fit-content;
    color: rgba($TechWhite, 0.3);
    transition: all 100ms ease-in-out;
    z-index: 1;

    &:hover {
      cursor: pointer;
      color: rgba($TechWhite, 1);
    }

    &--left {
      left: 1rem;
    }

    &--right {
      right: 1rem;
    }
  }

  &__indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;

    .carousel__indicator {
      background-color: $TechWhite;
      height: 1rem;
      width: 1rem;
      border-radius: 100%;
      border: none;
      box-shadow: 0px 0px 5px #555;
      margin: 0 0.7rem;
      cursor: pointer;

      &--inactive {
        background-color: $TechGray;
      }
    }
  }
}
