@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.plan {
  &__welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    flex-wrap: wrap;
    max-width: 140rem;
    margin: 0 auto;

    @include mobile-design {
      padding: 2rem 0;
    }

    .welcome__title {
      color: $TechPrimaryLight;
      margin-bottom: 1rem;

      @include tablet-design {
        max-width: 60rem;
        text-align: center;
      }

      @include mobile-design {
        font-size: 2.6rem;
        max-width: 90%;
        margin-bottom: 0;
      }
    }

    .welcome__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      padding-right: 2rem;

      @include mobile-design {
        flex-direction: column-reverse;
        margin: 0;
        padding: 0;
      }

      .welcome__aside {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3rem;

        @include tablet-design {
          padding: 0 0 0 2rem;
          display: initial;
        }

        @include mobile-design {
          padding: 0;
        }

        .welcome__text {
          max-width: 50rem;
          text-align: center;
          margin-right: 3rem;
          text-align: left;
          line-height: 2.4rem;

          @include tablet-design {
            margin-bottom: 1rem;
            clear: both;
            display: block;
          }

          @include mobile-design {
            margin: 0;
            text-align: justify;
            width: 100%;
            padding: 0 2rem;
          }

          &--link {
            color: $TechPrimary;
          }
        }
      }
    }

    .welcome__img {
      height: 30rem;
      width: 50rem;
      border-radius: 12px;

      @include tablet-design {
        height: auto;
        width: 32rem;
        aspect-ratio: 5/4;
        object-fit: cover;
        float: right;
      }

      @include mobile-design {
        float: none;
        width: 100%;
        aspect-ratio: 16/9;
        max-height: 30rem;
        border-radius: 0;
        margin: 2rem 0;

        @media (min-width: 550px) and (max-width: $mobile-breakpoint) {
          border-radius: 12px;
        }
      }
    }
  }

  &__timeMap {
    background-color: $TechPrimary;
    color: $TechWhite;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;

    @include tablet-design {
      padding: 4rem 2rem;
    }

    @include mobile-design {
      flex-direction: column;
      padding: 1rem;
    }

    .timeMap__times {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 8rem;

      @include tablet-design {
        margin-right: 3rem;
      }

      @include mobile-design {
        margin: 0;
        border: 3px solid $TechPrimaryPale;
        border-radius: 12px;
        padding: 2rem 1rem;
        width: 100%;
        max-width: 70rem;
      }

      .timeMap__title {
        color: $TechWhite;

        @include mobile-design {
          font-size: 2.6rem;
        }
      }

      .timeMap__list {
        list-style: none;
        margin: 1rem 0;

        @include mobile-design {
          width: 100%;
          max-width: 45rem;
        }

        .timeMap__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 40rem;
          margin: 1rem 0;

          @include tablet-design {
            width: 33rem;
          }

          @include mobile-design {
            width: 100%;
            padding: 0 1rem;

            .timeMap__service {
              width: 100%;
              max-width: 60%;
            }
          }
        }
      }

      .timeMap__address {
        font-size: 1.8rem;
        font-weight: 300;
        width: 24rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;

        @include mobile-design {
          margin: 2rem 0;
          font-size: 1.6rem;
          font-weight: normal;
        }
      }

      .timeMap__link {
        margin-top: 2rem;

        @include mobile-design {
          margin: 0;
        }
      }
    }

    .timeMap__map {
      height: 35rem;
      width: 50rem;
      border-radius: 12px;
      overflow: hidden;

      @include tablet-design {
        width: auto;
        flex-grow: 1;
        max-width: 50rem;
      }

      @include mobile-design {
        display: none;
        width: 100%;
        max-width: 60rem;
        flex-grow: initial;
        aspect-ratio: 4/3;
        height: auto;
        max-height: 40rem;
      }

      &--mobile {
        display: none;

        @include mobile-design {
          display: block;
        }
      }
    }
  }

  &__faq {
    width: 90%;
    max-width: 130rem;
    margin: 0 auto;
    padding: 3rem 0;

    @include mobile-design {
      padding: 2rem 0;
    }

    .faq__title {
      margin: 0 auto 3rem auto;
      width: fit-content;
      font-size: 3.6rem;

      @include mobile-design {
        font-size: 2.4rem;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $TechGrayDark;
    padding: 3rem 0;

    @include tablet-design {
      padding: 3rem 2rem;
    }

    .planContact__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: $TechWhite;
      text-align: center;

      .planContact__header {
        color: $TechWhite;
        letter-spacing: 2px;
      }

      .planContact__text {
        margin-top: 1rem;
      }
    }
  }
}
