@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.beliefValue {
  &__beliefs {
    max-width: 100rem;
    min-width: 80rem;
    width: 80%;
    margin: 3rem auto;

    @include tablet-design {
      max-width: 82rem;
      min-width: initial;
      width: 90%;
      margin: 2rem auto;
    }

    .beliefs__header {
      text-align: center;
    }

    .beliefs__list {
      list-style: none;

      #belief--0 {
        margin: 3rem 0 5rem 0;

        @include mobile-design {
          margin: 2rem 0 3rem 0;
        }
      }

      #belief--9 {
        margin: 5rem 0 2rem 0;

        @include mobile-design {
          margin: 3rem 0 2rem 0;
        }
      }

      .beliefs__belief {
        margin: 5rem 0;

        @include mobile-design {
          margin: 3rem 0;
        }

        .belief__desc {
          margin-top: 1rem;

          @include mobile-design {
            text-align: justify;
          }
        }
      }
    }

    .beliefs__link {
      font-weight: bold;
      text-decoration: initial;
      color: $TechPrimary;
    }
  }

  &__values {
    max-width: 100rem;
    min-width: 80rem;
    width: 80%;
    margin: 6rem auto;

    @include tablet-design {
      min-width: initial;
      width: 100%;
      max-width: 82rem;
      margin: 4rem auto;
    }

    @include mobile-design {
      margin-bottom: 1rem;
    }

    .values__header {
      text-align: center;
    }

    .values__container {
      width: 100%;
      aspect-ratio: 16/8;
      height: auto;
      overflow: hidden;

      @include tablet-design {
        width: 95%;
        margin: 0 auto;
        max-width: 82rem;
      }

      @include mobile-design {
        aspect-ratio: 4/3;
        max-width: 45rem;
      }

      .values__img {
        max-width: initial;
        max-height: initial;
        width: 110%;
        position: relative;
        left: -5rem;
        top: -6rem;

        @include tablet-design {
          left: -3rem;
        }

        @include mobile-design {
          display: none;
          width: 100%;
          left: initial;
          top: initial;
        }

        &--mobile {
          display: none;

          @include mobile-design {
            display: initial;
          }
        }
      }
    }

    .values__caption {
      max-width: 70rem;
      width: 80%;
      margin: 0 auto 4rem;
      text-align: justify;

      @include mobile-design {
        margin: 1rem auto 2rem;
      }
    }

    .values__wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      max-width: 86rem;
      margin: 0 auto;

      @include tablet-design {
        align-items: flex-start;
      }

      .values__value {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        max-width: 36rem;
        min-height: 29rem;
        height: fit-content;

        @include tablet-design {
          width: 32rem;
        }

        @include mobile-design {
          width: 80%;
          max-width: initial;
          min-height: initial;
          margin: 1rem 0 2rem;
        }

        .values__desc {
          margin: 2rem 0;
          text-align: justify;
        }

        .values__tag {
          font-weight: bold;

          @include mobile-design {
            text-align: center;
          }
        }
      }
    }
  }
}
