@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.navbar {
  width: 100%;
  max-width: 140rem;
  height: 7rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  translate: -50%;
  z-index: 7;

  @include tablet-design {
    height: 6rem;
  }

  @include mobile-design {
    min-height: 6rem;
    height: fit-content;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: $TechWhite;
  }

  &::before {
    content: "";
    position: fixed;
    z-index: -10;
    height: 7rem;
    width: 999rem;
    top: 0;
    left: -50%;
    background-color: $TechWhite;

    @include tablet-design {
      height: 6rem;
    }

    @include mobile-design {
      display: none;
    }
  }

  .logo__link {
    height: 100%;

    @include tablet-design {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20rem;
    }

    @include mobile-design {
      height: 6rem;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    height: 100%;

    @include mobile-design {
      flex-direction: column;
      justify-content: space-evenly;
      flex-grow: 1;
      height: fit-content;
      min-height: calc(100vh - 5.46rem);
      width: 100%;
    }

    .navbar__item {
      text-transform: uppercase;
      margin: 0 1rem;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 6px solid transparent;
      padding-top: 6px;
      font-size: 1.4rem;
      position: relative;

      @include mobile-design {
        height: fit-content;
        justify-content: center;
        width: 100%;
        font-family: CinzelBold;
        text-transform: lowercase;
        font-size: 2rem;
        color: $TechPrimary;
        margin: 1rem 0;
        flex-direction: column;
      }

      &:hover {
        border-bottom: 6px solid $TechPrimary;

        @include mobile-design {
          border-bottom: 6px solid transparent;
        }
      }

      .navbar__link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @include mobile-design {
          height: fit-content;
          justify-content: center;
        }
      }

      &--button {
        border-bottom: none;
        padding-top: 0;

        @include mobile-design {
          margin: 0 auto;
          width: fit-content;
        }

        &:hover {
          border-bottom: none;
        }
      }
    }
  }

  @include mobile-design {
    &__hidden {
      display: none;
    }
  }
}

.navMobile__trigger {
  height: 4rem;
  width: 4rem;
  display: none;
  margin: auto 1rem;

  @include mobile-design {
    display: block;
  }
}
