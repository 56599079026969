@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sermonCard {
  height: 42rem;
  width: 31%;
  border-radius: 12px;
  overflow: hidden;
  margin: 2rem 0;
  box-shadow: 2px 4px 5.9px rgba(0, 0, 0, 0.05);
  transition: all 100ms ease-in-out;
  border: 2px solid transparent;

  @include tablet-design {
    width: 31.5%;
    height: 37rem;
  }

  @include mobile-design {
    width: 100%;
    height: 8.4rem;
    margin: 1rem 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 550px) and (max-width: $mobile-breakpoint) {
      height: 10rem;
    }
  }

  &:hover {
    border: 2px solid $TechPrimary;
    box-shadow: 6px 8px 5.9px rgba(0, 0, 0, 0.15);

    @include mobile-design {
      width: 100%;
      margin: 0 0 2rem;
    }
  }

  &__wrapper {
    height: 18rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include tablet-design {
      height: 13rem;
    }

    @include mobile-design {
      height: 100%;
      width: 29%;
      min-width: 8rem;
      display: block;
      margin-right: 1rem;
    }

    .sermonCard__img {
      max-height: initial;
      max-width: initial;
      min-width: 30rem;
      min-height: 100%;
      height: auto;
      transition: width 1s ease-in-out;

      @include tablet-design {
        min-width: 25rem;
      }

      @include mobile-design {
        object-fit: cover;
        min-width: initial;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 24rem;
    padding: 1.5rem;

    @include mobile-design {
      height: 100%;
      padding: 0;
      width: 68%;
      justify-content: space-between;
    }

    .sermonCard__title {
      color: $TechPrimary;
      font-size: 2.4rem;
      text-transform: uppercase;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include tablet-design {
        font-size: 2rem;
        margin-bottom: 2px;
      }

      @include mobile-design {
        width: 100%;
        -webkit-line-clamp: 1;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 0;

        @media (min-width: 550px) and (max-width: $mobile-breakpoint) {
          font-size: 1.8rem;
          line-height: 2.7rem;
        }
      }
    }

    .sermonCard__reference {
      color: $TechPrimaryDark;
      text-transform: uppercase;
      letter-spacing: 1px;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include tablet-design {
        font-size: 1.4rem;
      }

      @include mobile-design {
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }

    .sermonCard__desc {
      -webkit-line-clamp: 4;
      line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: auto 0;

      @include mobile-design {
        display: none;
      }
    }

    .sermonCard__date {
      color: $TechPrimaryDark;

      @include mobile-design {
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }
  }
}
