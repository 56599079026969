@use "./partials/resets";
@use "./partials/variables" as *;
@use "./partials/mixins" as *;
@use "./partials/fonts";

html {
  scroll-padding-top: 70px;
}

body {
  font-family: NotoSerif, serif;
  color: $TechBlack;
  font-kerning: none;
}

h1,
h2,
h3 {
  font-family: CinzelBold;
  color: $TechPrimary;
  text-transform: capitalize;
}

h2 {
  font-size: 4rem;

  @include tablet-design {
    font-size: 3.6rem;
  }
}

h3 {
  font-size: 3.3rem;

  @include tablet-design {
    font-size: 2.9rem;
  }
}

.fontIcon--desktop {
  @include tablet-design {
    display: none;
  }
}

.fontIcon--tablet {
  display: none;

  @include tablet-design {
    display: initial;
  }
}

p {
  line-height: 2.2rem;
}
