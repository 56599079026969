@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.dropdown {
  position: absolute;
  top: 7rem;
  background-color: $TechWhite;
  color: $TechBlack;
  list-style: none;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  z-index: 10;

  @include tablet-design {
    top: 6rem;
  }

  @include mobile-design {
    padding: 0;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $TechPrimaryDark;
    color: $TechWhite;
    margin-top: 1rem;
    top: 0;
    font-family: NotoSerif;
    text-transform: uppercase;
  }

  &__item {
    margin: 1rem 0;

    @include mobile-design {
      margin: 1rem 0;
    }
  }

  &__link {
    line-height: 2rem;
    font-weight: 400;

    @include tablet-design {
      line-height: 2.5rem;
    }

    @include mobile-design {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    &:hover {
      border-bottom: 2px solid $TechPrimary;
      font-weight: 500;
    }
  }

  &--hidden {
    display: none;
  }
}
