@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.footer {
  @include mobile-design {
    background-color: $TechPrimary;
    padding-top: 1rem;
  }

  &--admin {
    @include mobile-design {
      background-color: none;
      padding: 0;
    }
  }

  &__map {
    background-color: $TechPrimary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;

    @include mobile-design {
      flex-direction: column-reverse;
      justify-content: space-between;
      padding: 0;
      border: 2px solid $TechWhite;
      border-radius: 12px;
      margin: 0 1rem 1rem;
    }

    .map__wrapper {
      height: 35rem;
      width: 50rem;
      border-radius: 12px;
      overflow: hidden;

      @include tablet-design {
        height: 28rem;
        width: 40rem;
      }

      @include mobile-design {
        width: 90%;
        min-width: 26rem;
        max-width: 60rem;
        height: 22rem;
        margin: 1rem 0 2rem;
      }
    }

    .map__content {
      border: 2px solid $TechWhite;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $TechWhite;
      padding: 2rem;
      height: 35rem;
      width: 35rem;
      border-radius: 12px;
      margin-left: 3rem;

      @include tablet-design {
        height: 28rem;
        width: 25rem;
      }

      @include mobile-design {
        border: none;
        margin: 0;
        width: 50%;
        min-width: 20rem;
        height: fit-content;
        padding: 2rem 0;
      }

      .map__logo {
        height: 10rem;
        width: 10rem;

        @include tablet-design {
          height: 8rem;
          width: 8rem;
        }

        @include mobile-design {
          height: 10rem;
          width: 10rem;
        }
      }

      .map__title {
        color: $TechWhite;
        width: 28rem;
        text-align: center;
        font-size: 2rem;
        margin: 2rem 0;

        @include tablet-design {
          width: fit-content;
          margin: 1.5rem 0;
        }
      }

      .map__address {
        margin-bottom: 2rem;

        @include tablet-design {
          width: 20rem;
          text-align: center;
          margin-bottom: 1.5rem;
        }
      }

      .map__phone {
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-phone {
          margin-right: 1rem;
        }

        .map__number {
          font-size: 1.8rem;
        }
      }
    }
  }

  &__foot {
    background-color: $TechBlack;
    color: $TechWhite;
    padding: 2rem 0;

    .foot__wrapper {
      max-width: 140rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4rem;

      @include tablet-design {
        padding: 0 2rem;
        max-width: 94.56rem;
      }

      @include mobile-design {
        width: 100%;
        padding: 0;
        flex-direction: column;
      }

      .foot__logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include mobile-design {
          order: 1;
          margin-top: 2rem;
        }

        .foot__img {
          width: 28rem;

          @include tablet-design {
            width: 20rem;
          }

          @include mobile-design {
            width: 28rem;
          }
        }

        .foot__copyright {
          width: 15rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: 200;
          font-size: 1.1rem;

          @include mobile-design {
            width: 100%;
            padding: 0 4rem;
            max-width: 37rem;
            margin: 1rem 0;
          }
        }
      }

      .foot__title {
        font-family: Cinzel;
        text-transform: lowercase;
        font-size: 1.5rem;
        width: 100%;
        text-align: center;

        @include mobile-design {
          letter-spacing: 2px;
        }
      }

      .foot__times {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile-design {
          width: 100%;
        }

        .foot__list {
          list-style: none;

          @include tablet-design {
            margin-top: 0.5rem;
          }

          @include mobile-design {
            width: 100%;
            margin: 0;
          }

          .foot__item {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: fit-content;
            margin: 1rem 0;
            text-transform: capitalize;
            font-size: 1.3rem;
            font-weight: 200;

            @include tablet-design {
              margin: 0.25rem 0;
              align-items: flex-end;
            }

            @include mobile-design {
              margin: 1rem auto;
              font-size: 1.5rem;
              width: 100%;
              max-width: 50rem;
              padding: 0 3rem;
            }

            .foot__service {
              width: 18rem;

              @include tablet-design {
                width: 11rem;
                margin-right: 1rem;
              }

              @include mobile-design {
                width: fit-content;
                max-width: 55%;
              }
            }

            .foot__time {
              width: 5.6rem;
              text-align: right;

              @include tablet-design {
                min-width: 5.6rem;
                width: fit-content;
              }

              @include mobile-design {
                width: 6.9rem;
              }
            }
          }
        }
      }

      .foot__links {
        display: flex;
        justify-content: space-evenly;
        text-transform: uppercase;
        flex-wrap: wrap;
        width: 20rem;

        @include tablet-design {
          min-width: 16rem;
          width: 23%;
          max-width: 25rem;
        }

        @include mobile-design {
          align-items: center;
          margin: 2rem 0 3rem;
          min-width: initial;
          max-width: 50rem;
          width: 100%;
          flex-grow: initial;

          .foot__title {
            margin-bottom: 1rem;
          }
        }

        .foot__link {
          font-size: 1.3rem;
          font-weight: 200;
          margin: 1rem 0;
          width: 40%;
          text-align: center;

          @include tablet-design {
            width: 50%;
          }

          @include mobile-design {
            margin: 1rem 0;
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
