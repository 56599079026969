@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.heroSmall {
  background-color: $TechPrimary;
  height: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12rem;
  background-size: cover;

  @include tablet-design {
    padding: 0 6rem;
  }

  @include mobile-design {
    height: 15rem;
    padding: 0;
  }

  &__text {
    color: $TechWhite;
    z-index: 5;
    width: 100%;
    max-width: 120rem;

    @include tablet-design {
      max-width: 78.6rem;
    }

    @include mobile-design {
      max-width: 85%;
      width: fit-content;
      min-width: 26rem;
      text-align: center;
      font-size: 3rem;
    }
  }

  &__overlay {
    position: absolute;
    background-color: rgba(102, 102, 102, 0.35);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  &--about,
  &--plan,
  &--videoError {
    background-image: url("../../assets/images/sanct1.JPG");
    background-position-y: 55%;
  }

  &--ministry {
    background-image: url("../../assets/images/youthActivity.jpg");
    background-position-y: 60%;
  }

  &--history {
    background-image: url("../../assets/images/bibleShelf.jpg");
    background-position-y: 60%;
  }

  &--staff {
    background-image: url("../../assets/images/smallGroup.jpg");
    background-position-y: 50%;
  }

  &--beliefs {
    background-image: url("../../assets/images/manBible.jpg");
    background-position-y: 40%;
  }

  &--partners {
    background-image: url("../../assets/images/partner.jpg");
    background-position-y: 65%;
  }

  &--sermons {
    background-image: url("../../assets/images/bibleOpen1.jpg");
    background-position-y: 28%;
  }

  &--contact {
    background-image: url("../../assets/images/rightStandard.jpeg");
    background-position-y: 70%;
  }

  &--give {
    background-image: url("../../assets/images/giveHands.jpg");
    background-position-y: 45%;
  }

  &--admin {
    background-image: url("../../assets/images/manPencil.jpg");
    background-position-y: 45%;
  }
}
