@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.partnersAffiliation {
  &__affiliations {
    max-width: 90rem;
    width: 80%;
    margin: 4rem auto 5rem;

    @include tablet-design {
      width: 90%;
      max-width: 82rem;
      hyphens: auto;
    }

    @include mobile-design {
      width: 100%;
      max-width: 62rem;
      padding: 1rem 2rem;
      margin: 0 auto;
    }

    .affiliations__title {
      text-align: center;

      @include mobile-design {
        font-size: 3rem;
      }
    }

    .affiliations__paragraph {
      text-indent: 3.2rem;
      margin: 2rem 0;

      @include mobile-design {
        margin: 1rem 0;
        text-align: justify;
      }
    }

    .affiliations__link {
      text-decoration: initial;
      color: $TechPrimary;
    }
  }

  &__partners {
    background-color: $TechPrimaryDark;
    color: $TechWhite;
    padding: 4rem 0 2rem;

    @include mobile-design {
      padding: 2rem;
      width: 100%;
    }

    .partners__title {
      color: $TechWhite;
      text-align: center;

      @include mobile-design {
        font-size: 3rem;
      }
    }

    .partners__list {
      list-style: none;
      width: 100%;
      max-width: 100rem;
      margin: 2rem auto 0;
    }
  }
}
