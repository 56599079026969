@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.leadership {
  padding-bottom: 4rem;

  @include mobile-design {
    padding-bottom: 2rem;
  }

  &__pastor,
  &__music {
    max-width: 110rem;
    width: 90%;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet-design {
      width: 95%;
      max-width: 92.3rem;
    }

    @include mobile-design {
      width: 100%;
      padding: 2rem 2rem 0;
      margin: 0 auto;
      justify-content: center;
    }

    .staff__content {
      max-width: 51rem;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;

      @include tablet-design {
        max-width: 56rem;
      }

      .staff__title {
        font-family: CinzelBold;
        text-transform: capitalize;
        letter-spacing: 5px;

        @include mobile-design {
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .staff__name {
        margin: 1rem 0 1rem;

        @include mobile-design {
          text-align: center;
          font-size: 2.5rem;
        }
      }

      .staff__paragraph {
        text-align: justify;
        margin: 1rem 0;
        line-height: 2.2rem;
      }
    }
  }

  &__pastor {
    .pastor__container {
      width: 45rem;
      height: auto;
      aspect-ratio: 4/5;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      margin-right: 5rem;

      @include tablet-design {
        width: 43%;
        margin-right: 3rem;
      }

      @include mobile-design {
        display: none;
        width: 70%;
        margin: 0 0 1rem;
        min-width: 23rem;
        max-width: 33rem;
      }

      .pastor__img {
        position: relative;
        top: 3rem;
        right: 1rem;
        max-width: initial;
        max-height: initial;
        width: 110%;
        height: auto;

        @include mobile-design {
          width: 120%;
        }
      }

      &--mobile {
        display: none;

        @include mobile-design {
          display: flex;
        }
      }
    }
  }

  &__music {
    .music__img {
      height: 36rem;
      width: 36rem;
      border-radius: 12px;

      @include tablet-design {
        width: 26.5rem;
        height: auto;
        margin-left: 4rem;
      }

      @include mobile-design {
        display: none;
        width: 60%;
        height: auto;
        margin: 0 0 1rem;
        min-width: 19rem;
        max-width: 29rem;
      }

      &--mobile {
        display: none;

        @include mobile-design {
          display: initial;
        }
      }
    }
  }

  &__support {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    max-width: 108rem;
    min-width: 90rem;
    margin: 6rem auto 3rem;

    @include tablet-design {
      min-width: initial;
      width: 90%;
      max-width: 90rem;
      padding: 0 2rem;
      column-gap: 3rem;
    }

    @include mobile-design {
      column-gap: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem 0 0;
      padding: 0;
      width: 100%;
    }

    .support {
      width: 40rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 2rem;

      @include tablet-design {
        width: 45%;
        margin: 0;
      }

      @include mobile-design {
        width: 85%;
        max-width: 50rem;
        margin-bottom: 2rem;
      }

      .support__img {
        width: 23rem;
        height: 23rem;
        overflow: hidden;
        border-radius: 12px;

        @include tablet-design {
          width: 20rem;
          height: 20rem;
        }

        @include mobile-design {
          order: 3;
          margin-bottom: 2rem;
          width: 60%;
          height: auto;
          aspect-ratio: 1/1;
          min-width: 16rem;
          max-width: 23rem;
        }

        .support__michelle,
        .support__marck {
          max-width: initial;
          max-height: initial;
          width: 23.3rem;
          height: auto;
          position: relative;
          bottom: 4.3rem;

          @include tablet-design {
            left: -1.5rem;
          }

          @include mobile-design {
            width: 110%;
          }
        }

        .support__marck {
          bottom: 3rem;
        }
      }

      .support__title {
        font-family: CinzelBold;
        text-transform: capitalize;
        letter-spacing: 5px;
        margin-top: 2rem;
        text-align: center;

        @include mobile-design {
          order: 1;
          margin: 0;
        }
      }

      .support__name {
        font-family: CinzelBold;
        color: $TechPrimary;
        text-transform: capitalize;
        font-size: 3rem;
        text-align: center;
        margin: 1rem 0;
        line-height: 4.1rem;
        width: 19rem;

        @include mobile-design {
          order: 2;
          font-size: 2rem;
          width: fit-content;
          margin-top: 0;
        }
      }

      .support__paragraph {
        text-align: justify;
        line-height: 2.2rem;

        @include mobile-design {
          order: 4;
        }
      }
    }
  }

  &__deacons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .deacons__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100rem;
      width: 80%;
      margin-top: 2rem;

      @include tablet-design {
        width: 100%;
        max-width: 80rem;
        padding: 0 2rem;
      }

      @include mobile-design {
        flex-direction: column;
      }

      .deacon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mobile-design {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          max-width: 39rem;
          margin: 1rem 0;
        }

        .deacon__container {
          background-color: $TechPrimaryDark;
          border-radius: 12px;
          width: 16rem;
          height: 16rem;
          overflow: hidden;
          position: relative;

          @include tablet-design {
            width: 12rem;
            height: 12rem;
          }

          @include mobile-design {
            aspect-ratio: 1/1;
            width: 35%;
            min-width: 9rem;
            max-width: 13rem;
            height: auto;
            margin-right: 1rem;
          }

          .deacon__img {
            position: relative;
            max-width: initial;
            max-height: initial;
            width: 19rem;
            height: 19rem;
            bottom: -3rem;
            left: -1.5rem;

            @include tablet-design {
              height: 14rem;
              width: 14rem;
              left: -1rem;
              bottom: -2rem;
            }

            @include mobile-design {
              width: 120%;
              height: auto;
              left: -1rem;
              bottom: -1.5rem;
            }
          }
        }

        .deacon__name {
          font-family: CinzelBold;
          color: $TechPrimary;
          text-transform: capitalize;
          font-size: 2.8rem;
          width: min-content;
          text-align: center;
          margin-top: 2rem;

          @include mobile-design {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
