@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sermonsPage__recent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  width: 80%;
  max-width: 110rem;
  border-radius: 12px;
  overflow: hidden;
  margin: 2rem auto;
  box-shadow: 2px 4px 5.9px rgba(0, 0, 0, 0.08);
  transition: all 100ms ease-in-out;

  @include tablet-design {
    width: 93%;
  }

  @include mobile-design {
    margin-top: 1rem;
    flex-direction: column;
    height: 45rem;
    max-width: 60rem;
  }

  &:hover {
    height: 41rem;
    margin-bottom: 1rem;
    width: 82%;
    max-width: 111rem;
    box-shadow: 2px 6px 7.9px rgba(0, 0, 0, 0.1);

    @include tablet-design {
      width: 95%;
    }

    @include mobile-design {
      height: 46rem;
    }
  }

  .recent__wrapper {
    height: 100%;
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include mobile-design {
      width: 100%;
      height: 49%;
    }

    .recent__img {
      max-height: initial;
      max-width: initial;
      width: auto;
      height: 100%;
      transition: width 1s ease-in-out;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .recent__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 35%;
    padding: 0 2rem;

    @include mobile-design {
      width: 100%;
      height: 51%;
      padding: 2rem;
    }

    .recent__title {
      color: $TechPrimary;
      font-size: 2.4rem;
      text-transform: uppercase;

      @include mobile-design {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        width: 100%;
      }
    }

    .recent__reference {
      color: $TechPrimaryDark;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 1rem;

      @include mobile-design {
        font-weight: bold;
        font-size: 1.5rem;
      }
    }

    .recent__desc {
      -webkit-line-clamp: 4;
      line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 3rem 0;

      @include mobile-design {
        -webkit-line-clamp: 3;
        margin: auto 0;
      }
    }

    .recent__date {
      color: $TechPrimaryDark;

      @include mobile-design {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
