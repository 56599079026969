@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.ministryCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: relative;

  @include tablet-design {
    padding: 2rem 2rem;
  }

  @include mobile-design {
    padding: 2rem 0 0;
  }

  .ministryCard__img {
    height: 42rem;
    width: 44rem;
    object-fit: cover;
    margin: 0 3rem 0 0;
    border-radius: 12px;

    @include tablet-design {
      height: 28rem;
      width: 33rem;
      margin: 0 2rem 0 0;
      float: left;
    }

    @include mobile-design {
      float: none;
      margin: 0 0 1rem;
      border-radius: 0;
      height: 14rem;
      width: 100%;
    }

    @include tablet-design {
      &--desktop {
        display: none;
      }
    }

    &--tablet {
      display: none;

      @include tablet-design {
        display: initial;
      }
    }
  }

  &__text {
    width: 60%;
    max-width: 68rem;

    @include tablet-design {
      width: 100%;
      max-width: 80rem;
    }

    @include mobile-design {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .ministryCard__title {
      text-align: center;
      margin-bottom: 2rem;

      @include mobile-design {
        width: 100%;
        max-width: 90%;
        word-break: break-word;
        font-size: 2.3rem;
        margin: 0 0 2rem;
        order: -1;
      }
    }

    .ministryCard__paragraph {
      margin: 1rem 0;
      line-height: 2.4rem;

      @include mobile-design {
        padding: 0 2rem;
        text-align: justify;
        margin-bottom: 0;
        max-width: 60rem;
      }
    }
  }

  &--ALT {
    flex-direction: row-reverse;

    .ministryCard__img {
      margin: 0 0 0 2rem;

      @include tablet-design {
        margin: 0 0 0 2rem;
        float: right;
      }

      @include mobile-design {
        float: none;
        margin: 0 0 1rem;
      }
    }
  }

  &--youth,
  &--menWomen,
  &--benevolence {
    background-color: $TechPrimary;
    color: $TechWhite;

    .ministryCard__title {
      color: $TechWhite;
    }
  }

  &--care {
    @include mobile-design {
      padding-top: 0;

      .ministryCard__title {
        margin: 0;
      }
    }

    .ministryCard__img {
      width: 42rem;
      margin-left: 3rem;
      object-position: 47%;

      @include tablet-design {
        width: 30rem;
        margin-left: 2rem;
      }

      @include mobile-design {
        height: auto;
        min-width: 30rem;
        max-width: 40rem;
        width: 90%;
        margin: 0;
      }
    }
  }

  @include mobile-design {
    &--benevolence,
    &--growth,
    &--food,
    &--care {
      padding-bottom: 2rem;
    }
  }
}
