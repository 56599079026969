@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.adminSlide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 3rem;
  flex-direction: column;
  max-width: 150rem;
  margin: 0 auto;

  &__topBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    margin-bottom: 2rem;

    @media (max-width: 757px) {
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .adminSlide__title {
      font-size: 3rem;
      margin-right: 2rem;
    }

    @media (max-width: 757px) {
      .adminSlide__buttons {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }

  &__wrapper {
    width: 90%;
    height: 55rem;
  }
}
