@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sermonsPage {
  &__labelSearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 110rem;
    margin: 2rem auto 1rem;

    @include tablet-design {
      width: 100%;
      padding: 0 2rem;
    }

    @include mobile-design {
      padding: 0 1rem;
      margin: 1rem auto;
      max-width: 61.5rem;
      flex-wrap: wrap;
    }

    &--alt {
      justify-content: flex-end;
      margin-bottom: 0;
    }

    .sermonsPage__label {
      font-family: CinzelBold;
      color: $TechPrimary;
      font-size: 1.8rem;

      @include mobile-design {
        font-size: 1.4rem;
        margin-right: 1rem;
        min-width: 11rem;
      }
    }

    .sermonsPage__select {
      font-family: NotoSerif;
      border: 2px solid $TechGrayLight;
      border-radius: 8px;
      color: $TechGrayDark;
      margin-left: auto;

      @include mobile-design {
        padding: 0.5rem;
        font-size: 1.4rem;
        border-radius: 12px;
      }

      &:focus {
        border: 2px solid $TechPrimary;
        outline: $TechPrimary;
      }
    }

    .sermonsPage__searchWrapper {
      position: relative;
      margin-left: 1rem;

      @include mobile-design {
        max-width: 33rem;
      }

      @media (max-width: 520px) {
        width: 100%;
        max-width: none;
        margin: 1rem auto;
        order: -1;
      }

      .sermonsPage__search {
        background-color: $TechGrayLight;
        border: 2px solid transparent;
        border-radius: 2rem;
        height: fit-content;
        width: 22rem;
        color: $TechGrayDark;
        padding: 0.8rem 0.8rem 0.8rem 2.8rem;
        font-family: NotoSerif;

        @include mobile-design {
          width: 100%;
          padding: 0.5rem 0.5rem 0.5rem 2.8rem;
        }

        &:focus {
          border: 2px solid $TechPrimary;
        }
      }

      .fa-magnifying-glass {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: $TechGrayDark;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    max-width: 110rem;
    margin: 0 auto -2rem;

    @include tablet-design {
      width: 93%;
    }

    @include mobile-design {
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
      width: 100%;
      max-width: 60rem;
      padding: 0 1rem;
    }

    .sermonsPage__categoryWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 2rem;
      padding-top: 1rem;

      @include tablet-design {
        column-gap: 1rem;
      }

      .sermonsPage__category {
        width: 100%;
        color: $TechWhite;
        background-color: $TechPrimary;
        padding: 1rem;
        border-radius: 12px;
        text-align: center;
        letter-spacing: 2px;

        @include mobile-design {
          font-size: 2.4rem;
        }
      }
    }
  }

  &__viewAll {
    width: 80%;
    max-width: 110rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 2rem auto;

    @include mobile-design {
      width: 100%;
      max-width: 60rem;
      padding: 0 1rem;
    }

    .viewAll__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $TechPrimary;
      font-weight: bold;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: all 200ms ease-in-out;

      &:hover {
        border-color: $TechPrimary;
      }

      .fa-chevron-right {
        margin-left: 0.5rem;
        color: $TechPrimary;
      }
    }
  }
}
