@font-face {
  font-family: NotoSerif;
  src: local(NotoSerif),
    url("../../assets/fonts/NotoSerif-VariableFont_wdth\,wght.ttf");
}

@font-face {
  font-family: NotoSerifItalic;
  src: local(NotoSerifItalic),
    url("../../assets/fonts/NotoSerif-Italic-VariableFont_wdth\,wght.ttf");
}

@font-face {
  font-family: Cinzel;
  src: local(Cinzel), url("../../assets/fonts/CinzelDecorative-Regular.ttf");
}

@font-face {
  font-family: CinzelBold;
  src: local(CinzelBold), url("../../assets/fonts/CinzelDecorative-Bold.ttf");
}

@font-face {
  font-family: CinzelBlack;
  src: local(CinzelBlack), url("../../assets/fonts/CinzelDecorative-Black.ttf");
}
