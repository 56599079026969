@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.timeline {
  position: relative;
  overflow: hidden;
  padding: 4rem 0;

  @include mobile-design {
    max-width: 50rem;
    margin: 0 auto;
    padding: 2rem 0 3rem;
  }

  &__main {
    position: absolute;
    background-color: $TechGrayDark;
    width: 1rem;
    height: 126rem;
    border: none;
    border-radius: 1.5rem;
    left: 50%;
    right: 50%;
    top: 7rem;
    overflow: visible;

    @include tablet-design {
      height: 119rem;
    }

    @include mobile-design {
      top: 2rem;
      left: 1.5rem;
      right: initial;
      height: calc(100% - 7rem);
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      background-color: $TechPrimaryLight;
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 96rem;
    margin: 0 auto;
    position: relative;

    @include tablet-design {
      width: 60rem;
    }

    @include mobile-design {
      width: 100%;
      padding: 0 2rem;
      align-items: flex-start;
    }

    #tDate--0,
    #tDate--11 {
      left: initial;
      right: initial;

      .timeline__branch {
        display: none;
      }
    }

    #tDate--0 {
      left: -18rem;

      @include tablet-design {
        left: -16rem;
      }

      @include mobile-design {
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        justify-content: center;
        padding: 0 0 0 1rem;
        margin: 0;

        .timeline__text {
          width: 80%;
          flex-grow: 0;
        }
      }
    }

    #tDate--11 {
      right: -22rem;

      @include tablet-design {
        right: -18rem;
      }

      @include mobile-design {
        right: initial;
        left: 50%;
        transform: translateX(-50%);
        top: 1rem;
        justify-content: center;
        padding: 0 0 0 1rem;
        margin-top: 0;

        .timeline__text {
          width: 80%;
          flex-grow: 0;
        }
      }
    }

    .timeline__entry {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: fit-content;
      left: 25%;

      @include tablet-design {
        left: 30%;
      }

      @include mobile-design {
        left: initial;
        width: 100%;
        min-width: 30rem;
        justify-content: space-between;
        padding-right: 2rem;
        margin: 1rem 0;
      }

      .timeline__branch {
        position: relative;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5rem;

        @include tablet-design {
          margin-right: 2rem;
        }

        @include mobile-design {
          margin-right: 1rem;
          min-width: 5rem;
          flex-grow: 1;
          max-width: 7rem;
        }

        .timeline__line {
          height: 5px;
          width: 15rem;
          background-color: $TechGrayDark;
          border: none;

          @include tablet-design {
            width: 7rem;
          }

          @include mobile-design {
            width: 100%;
          }
        }

        .timeline__node {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background-color: $TechPrimaryLight;
          position: absolute;
          right: 0;
        }
      }

      .timeline__text {
        text-align: center;
        width: 28rem;

        @include tablet-design {
          width: 26rem;
        }

        @include mobile-design {
          width: 20rem;
          max-width: 36rem;
          flex-grow: 1;
        }
      }

      &--ALT {
        flex-direction: row-reverse;
        left: initial;
        right: 25%;

        @include tablet-design {
          right: 29%;
        }

        @include mobile-design {
          right: initial;
          flex-direction: row;
          left: initial;
        }

        .timeline__branch {
          margin: 0 0 0 5rem;

          @include tablet-design {
            margin: 0 0 0 2rem;
          }

          @include mobile-design {
            margin: 0 1rem 0 0;
          }

          .timeline__node {
            right: initial;
            left: 0;

            @include mobile-design {
              right: 0;
              left: initial;
            }
          }
        }
      }
    }
  }
}
