@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.partnerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 26.2rem;
  margin: 2rem 0;

  @include mobile-design {
    width: 100%;
    max-width: 50rem;
    flex-direction: column;
    max-height: initial;
    height: fit-content;
    margin: 2rem auto;
  }

  .partnerCard__wrapper {
    width: 30rem;
    height: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile-design {
      width: 80%;
      max-width: 30rem;
      height: auto;
      margin-bottom: 2rem;
    }
  }

  .partnerCard__content {
    width: 80%;
    max-width: 63rem;
    margin-left: 7rem;

    @include tablet-design {
      margin-left: 3rem;
    }

    @include mobile-design {
      margin: 0;
      width: 100%;
    }

    .partnerCard__title {
      font-size: 3rem;
      color: $TechWhite;
      margin-bottom: 1rem;
      font-family: NotoSerif;
      text-transform: uppercase;
      max-width: 59rem;
      letter-spacing: 3px;

      @include tablet-design {
        font-size: 2.4rem;
        letter-spacing: 2px;
        min-width: 44rem;
      }

      @include mobile-design {
        margin-bottom: 2rem;
        text-align: center;
        width: 100%;
        min-width: initial;
        max-width: initial;
      }
    }

    .partnerCard__desc {
      max-width: 56rem;

      @include mobile-design {
        max-width: initial;
        text-align: center;
      }
    }
  }

  &--niangua,
  &--d6,
  &--randall,
  &--v3 {
    .partnerCard__img {
      max-height: 15rem;
    }
  }

  @include mobile-design {
    &--missFWB {
      .partnerCard__wrapper {
        width: 90%;
        margin-bottom: 1rem;
      }
    }

    &--nafwb {
      .partnerCard__wrapper {
        margin-bottom: 1rem;

        .partnerCard__img {
          max-height: 20rem;
        }
      }
    }

    &--welch {
      .partnerCard__wrapper {
        width: 100%;
        margin-bottom: 0;
      }
    }

    &--v3 {
      .partnerCard__wrapper {
        margin-bottom: 0;
      }
    }
  }

  &--randall {
    margin-bottom: 0;

    @include mobile-design {
      margin-bottom: 1rem;
    }
  }
}
