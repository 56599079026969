@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.button {
  height: fit-content;
  border: none;
  min-width: 10rem;
  width: fit-content;
  padding: 1.2rem 2rem;
  background-color: $TechPrimary;
  color: $TechWhite;
  border-radius: 10px;
  font-family: Cinzel;
  letter-spacing: 2px;
  text-transform: capitalize;

  &:hover {
    background-color: $TechPrimaryDark;
    color: $TechPrimaryPale;
    cursor: pointer;
  }

  &--welcome {
    padding: 1.2rem 4rem;
  }

  &--planVisit,
  &--ministry {
    background-color: $TechPrimaryDark;

    @include mobile-design {
      display: none;
    }

    &:hover {
      color: $TechWhite;
      background-color: $TechPrimaryLight;
    }
  }

  &--navbar {
    text-transform: lowercase;

    @include tablet-design {
      font-size: 1.2rem;
      padding: 1rem 2rem;
    }

    @include mobile-design {
      font-size: 1.6rem;
      padding: 1.5rem 4rem;
    }
  }

  &--footer {
    background-color: transparent;
    border: 2px solid $TechWhite;

    @include tablet-design {
      padding: 1.2rem 1rem;
    }

    @include mobile-design {
      letter-spacing: 4px;
      padding: 1.5rem 3rem;
    }

    &:hover {
      background-color: $TechPrimary;
    }
  }

  &--about {
    display: block;
    margin: 0 auto;

    @include tablet-design {
      margin-top: 3rem;
      padding: 1.5rem 3rem;
    }

    @include mobile-design {
      margin: 0;
      width: 100%;
      text-transform: lowercase;
      font-weight: bold;
    }
  }

  &--map {
    @extend .button--footer;

    &:hover {
      background-color: $TechPrimaryDark;
      color: $TechWhite;
    }
  }

  &--contact {
    font-family: NotoSerif;
    text-transform: uppercase;
    width: 24rem;
    height: 4.9rem;

    @include tablet-design {
      width: 49%;
      max-width: 20rem;
    }

    @include mobile-design {
      width: 100%;
      max-width: 40rem;
      margin-top: 1rem;
    }
  }

  &--give {
    font-kerning: none;
    letter-spacing: 2px;
    border: 2px solid transparent;

    @include mobile-design {
      color: $TechWhite;
      font-family: CinzelBold;
    }

    &:hover {
      background-color: $TechPrimaryDark;
      color: $TechWhite;
      border-color: $TechWhite;
    }
  }

  &--admin {
    width: 20rem;
    margin-top: 1rem;
    font-family: NotoSerif;
    font-weight: 300;
    font-size: 1.6rem;
  }

  &--adminNav {
    background-color: transparent;
    border: 2px solid $TechWhite;
    text-transform: lowercase;

    @include tablet-design {
      font-size: 1.2rem;
      padding: 1rem 2rem;
    }

    @include mobile-design {
      font-size: 1.6rem;
      padding: 1.5rem 4rem;
    }
  }

  &--adminDelete,
  &--adminNewRow,
  &--adminNewImg,
  &--adminSave,
  &--adminSubmit {
    font-family: NotoSerif;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  &--adminDelete {
    background-color: rgb(142, 36, 36);

    &:hover {
      background-color: rgb(92, 25, 25);
    }
  }

  &--adminNewImg {
    margin: 0 1rem 0 2rem;

    @include tablet-design {
      margin: 0 1rem;
    }
  }

  &--adminNewRow {
    margin: 0 2rem 0 1rem;

    @include tablet-design {
      margin: 0 1rem;
    }
  }

  &--adminSave {
    background-color: rgb(56, 127, 56);

    &:hover {
      background-color: rgb(20, 93, 20);
    }
  }
}
