@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contactForm {
  max-width: 70rem;
  width: 100%;
  padding: 2rem 0;

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .contactForm__name,
    .contactForm__email,
    .contactForm__subject,
    .contactForm__message {
      border: 2px solid transparent;
      color: $TechBlack;
      background-color: $TechGrayLight;
      border-radius: 8px;
      margin: 9px 0;
      height: 5rem;
      padding: 1rem;
      font-family: NotoSerif;

      &:focus-visible {
        outline: none;
        border-color: $TechPrimary;
      }
    }

    .contactForm__name,
    .contactForm__email {
      width: 49%;

      @include mobile-design {
        width: 100%;
      }
    }

    .contactForm__subject {
      width: 100%;
    }

    .contactForm__message {
      width: 100%;
      height: 20rem;
      resize: none;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }

    .contactForm__wrapper {
      width: 100%;
      max-width: 54rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 9px auto;

      @include tablet-design {
        max-width: 45rem;
      }

      @include mobile-design {
        max-width: initial;
        flex-direction: column;
      }

      .contactForm__recaptcha {
        width: 24rem;
        height: 4.9rem;
        background-color: $TechGrayLight;
        border-radius: 10px;
        display: none;

        @include tablet-design {
          width: 49%;
          max-width: 20rem;
        }

        @include mobile-design {
          width: 100%;
          max-width: 40rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &--home {
    width: 50%;
    flex-grow: 1;

    @include tablet-design {
      max-width: 60rem;
    }

    @include mobile-design {
      width: 100%;
      max-width: 70rem;
      flex-grow: initial;
      padding-bottom: 1rem;
    }
  }

  &--plan {
    max-width: 90rem;
    padding-bottom: 0;
  }
}
