@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.historyPage {
  &__paragraph {
    width: 90%;
    max-width: 110rem;
    margin: 2rem auto;
    text-indent: 2rem;

    @include tablet-design {
      width: 100%;
      padding: 0 2rem;
    }

    @include mobile-design {
      text-align: justify;
    }
  }

  &__img {
    display: block;
    width: 80%;
    max-width: 90rem;
    margin: 2rem auto;
  }
}
