@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 3rem;
  background-color: $TechPrimaryDark;

  @include mobile-design {
    padding: 2rem;
  }

  &__heading {
    color: $TechWhite;
    margin-bottom: 1rem;

    @include mobile-design {
      font-size: 2.4rem;
    }
  }

  &__text {
    color: $TechWhite;
    max-width: 56rem;
    text-align: center;
    font-family: Cinzel;
    letter-spacing: 1px;

    @include mobile-design {
      font-size: 1.4rem;
    }
  }
}
