$TechBlack: #1d1d1d;
$TechPrimaryDark: #0f3f4a;
$TechPrimary: #176476;
$TechPrimaryLight: #2e98b2;
$TechPrimaryPale: #d0dfe3;
$TechGrayDark: #3c474b;
$TechGray: #a3a7ab;
$TechGrayLight: #e7e7e7;
$TechWhite: #ffffff;

$desktopXL-breakpoint: 1440px;
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 743px;
