@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.modalCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($TechGray, 0.5);
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  @include mobile-design {
    top: -6rem;
    position: absolute;
    height: calc(100% + 6rem);
    align-items: flex-start;
    padding-top: 4rem;
  }

  &__form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: $TechWhite;
    border-radius: 12px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    min-width: 46rem;

    @include mobile-design {
      width: 100%;
      min-width: 32rem;
      height: fit-content;
      max-width: 46rem;
    }

    .modalCarousel__header {
      font-family: NotoSerif;
      width: 100%;
      color: $TechWhite;
      background-color: $TechPrimary;
      padding: 1rem 0;
      text-align: center;
    }

    .modalCarousel__body {
      width: 100%;
      padding: 0 2rem;
    }

    .modalCarousel__label {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 1.5rem;
      width: 100%;
    }

    .modalCarousel__wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;

      &--fileNotice {
        align-items: flex-start;

        .modalCarousel__files {
          width: 100%;
        }

        .modalCarousel__links {
          width: 100%;
        }

        .modalCarousel__notice {
          width: 30%;
          width: 100%;
          margin-top: 1.5rem;

          .modalCarousel__notice-text {
            font-size: 1.3rem;
            text-align: center;
          }
        }
      }
    }

    .modalCarousel__req {
      font-size: 2.2rem;
      line-height: 1rem;
      margin-right: 2px;
      color: rgb(153, 11, 11);
    }

    .modalCarousel__input {
      height: fit-content;
      padding: 0.5rem 1rem;
      border: 2px solid rgba($TechGrayLight, 1);
      border-radius: 4px;
      margin-top: 0.2rem;
      width: 100%;

      &:focus-visible {
        outline: none;
        border-color: $TechPrimary;
      }
    }

    .modalCarousel__button-wrapper {
      margin: 1.5rem 0 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
