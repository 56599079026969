@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.FAQCard {
  &__line {
    border: 2px solid $TechPrimary;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.6rem auto;
    width: 95%;

    .FAQCard__question {
      font-size: 2.7rem;

      @include mobile-design {
        font-size: 1.8rem;
        max-width: 85%;
      }
    }
  }

  &__answer {
    width: 90%;
    margin: 0 auto 2.4rem;
    line-height: 2.5rem;

    @include mobile-design {
      width: 90%;
      // padding: 0 1rem;
    }

    .FAQCard__paragraph {
      margin: 1rem 0;
    }
  }
}
