*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  color: black;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}
