@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.homepage {
  &__hero {
    width: 100%;
    background-color: $TechPrimary;

    .hero__container {
      background-color: $TechPrimaryPale;
      background-image: url(../../assets/images/church.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 60%;
      height: 58vh;
      min-height: 30rem;
      max-height: 40rem;
      position: relative;

      @include desktopXL-design {
        max-height: 60rem;
      }

      @include tablet-design {
        height: 30rem;
      }

      @include mobile-design {
        background-position-x: 45%;
      }

      .hero__text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        max-width: 100rem;
        transform: translate(-50%, -50%);
        text-shadow: 8px 8px 10px rgba(0, 0, 0, 0.32);

        &--small {
          font-size: 4.8rem;
          color: $TechWhite;

          @include tablet-design {
            font-size: 4rem;
          }

          @include mobile-design {
            font-size: 3rem;
          }
        }

        &--big {
          font-size: 10rem;
          color: $TechWhite;

          @include tablet-design {
            font-size: 9rem;
          }

          @include mobile-design {
            font-size: 6rem;
            text-indent: 2rem;
          }
        }
      }
    }
  }

  &__welcome {
    height: 38rem;
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include tablet-design {
      height: 34rem;
    }

    @include mobile-design {
      flex-direction: column-reverse;
      height: fit-content;
      margin: 1rem;
      border: 4px solid $TechPrimary;
      border-radius: 12px;
      padding: 1rem;
    }

    .welcome__text {
      max-width: 45rem;
      margin: 0 2rem 0 6rem;

      @include tablet-design {
        margin: 0 0 0 4rem;
      }

      @include mobile-design {
        margin: 1rem 0;
        text-align: center;

        .welcome__heading {
          font-size: 1.8rem;
        }
      }

      .welcome__phrase {
        color: $TechBlack;
        margin: 1rem 0 2rem 0;
      }
    }

    .welcome__img {
      max-width: initial;
      max-height: initial;
      height: 47rem;

      @include desktopXL-design {
        height: 50rem;
      }

      @include tablet-design {
        height: 40rem;
      }

      @include mobile-design {
        height: 30rem;
        margin: -5rem 0 -4rem;
      }
    }
  }

  &__redirects {
    background-color: $TechPrimary;

    @include mobile-design {
      padding: 1rem;
    }

    .redirect__card {
      color: $TechWhite;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto;

      @include tablet-design {
        width: 93%;
      }

      @include mobile-design {
        position: relative;
        width: 100%;
        max-width: 70rem;
        border-radius: 12px;
        overflow: hidden;
      }

      &--top {
        padding: 4rem 0 2rem 0;

        @include mobile-design {
          padding: 0;
          margin: 1rem auto 2rem;
        }
      }

      &--bottom {
        padding: 2rem 0 4rem 0;

        @include mobile-design {
          padding: 0;
          margin: 2rem auto 1rem;
        }
      }

      .redirect__text {
        max-width: 52rem;
        margin-right: 6rem;

        @include desktopXL-design {
          margin-right: 10rem;
        }

        @include tablet-design {
          margin-right: 2rem;
        }

        @include mobile-design {
          margin: 0;
          position: absolute;
          background-color: rgba($TechPrimaryDark, 0.35);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          backdrop-filter: blur(1px);
          max-width: initial;
        }

        .redirect__heading {
          color: $TechWhite;

          @include mobile-design {
            display: none;
          }

          &--plan,
          &--ministry {
            display: none;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
            font-size: 3.2rem;
            width: 24rem;
            text-align: center;

            @include mobile-design {
              display: block;
            }
          }

          &--ministry {
            @include mobile-design {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }

            .redirect__subheading {
              font-size: 1.6rem;
              letter-spacing: 3px;
            }
          }
        }

        .redirect__phrase {
          margin: 1rem 0;
          line-height: 2.4rem;

          @include mobile-design {
            display: none;
          }

          &--underline {
            text-decoration: underline;
          }
        }
      }

      .redirect__img {
        width: auto;
        height: 100%;
        aspect-ratio: 3/2;
        max-height: 21rem;
        border-radius: 12px;
        object-fit: cover;

        @include tablet-design {
          max-height: 19rem;
        }

        @include mobile-design {
          width: 100%;
          height: auto;
          aspect-ratio: 2/1;
          border-radius: initial;
        }
      }
    }
  }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;

    @include mobile-design {
      padding: 2rem 1rem;
      max-width: 72rem;
      margin: 0 auto;
    }

    .social__heading {
      width: 100%;
      text-align: center;
      margin-top: 1rem;

      @include mobile-design {
        font-size: 3rem;
        margin: 0 0 1rem;
      }
    }

    .social__card {
      height: 20rem;
      width: 40rem;
      background-size: 100%;
      background-repeat: no-repeat;
      border-radius: 12px;
      overflow: hidden;
      margin: 2rem;

      @include tablet-design {
        width: 33rem;
      }

      @include mobile-design {
        width: 100%;
        height: 11rem;
      }

      &--youtube {
        background-image: url("../../assets/images/youtube.jpg");

        @include tablet-design {
          margin-right: 1rem;
        }

        @include mobile-design {
          margin: 1rem 0;
        }
      }

      &--facebook {
        background-image: url("../../assets/images/facebook.jpg");

        @include tablet-design {
          margin-left: 1rem;
        }

        @include mobile-design {
          background-image: url("../../assets/images/facebook2.jpg");
          background-position-y: 40%;
          margin: 1rem 0;
        }
      }

      .social__wrapper {
        height: 100%;
        width: 100%;
        backdrop-filter: blur(2px) grayscale(30%);
        background-image: linear-gradient(
          to bottom right,
          rgba($TechPrimary, 0.4) 45%,
          rgba($TechPrimaryPale, 0.4) 100%
        );
        color: $TechWhite;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile-design {
          backdrop-filter: blur(1px);
        }

        .social__phrase {
          font-family: Cinzel;
          text-transform: capitalize;
          width: 20rem;
          text-align: center;

          @include mobile-design {
            display: none;
          }
        }

        .social__title {
          color: $TechWhite;
          margin-top: 1rem;
          text-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $TechGrayDark;
    padding: 1rem 4rem;

    @include tablet-design {
      padding: 1rem 2rem;
    }

    @include mobile-design {
      flex-direction: column;
      padding: 1rem;
    }

    .homeContact__content {
      width: 37rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: $TechWhite;
      padding: 0 2rem;
      margin-right: 2rem;
      text-align: center;

      @include tablet-design {
        width: 28rem;
        padding: 0;
      }

      @include mobile-design {
        width: 100%;
        margin: 0;
      }

      .homeContact__header {
        color: $TechWhite;

        @include mobile-design {
          font-size: 3rem;
          margin-top: 1rem;
        }
      }

      .homeContact__text {
        margin: 2rem 0;

        @include mobile-design {
          width: 90%;
          min-width: 29rem;
          line-height: 3rem;
          margin: 1rem 0 1.5rem;
        }
      }

      .homeContact__phone {
        display: flex;
        justify-content: center;
        align-items: center;

        .fa-phone {
          margin-right: 1rem;
        }

        .homeContact__number {
          font-size: 2rem;
          letter-spacing: 2px;

          @include mobile-design {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}
