@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.about {
  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 85%;
    height: fit-content;
    max-width: 110rem;
    margin: 0 auto;

    @include tablet-design {
      width: 90%;
    }

    @include mobile-design {
      width: 95%;
    }

    .about__embed {
      height: auto;
      width: 100%;
      border-radius: 12px;
      aspect-ratio: 16/9;
    }
  }

  &__values {
    color: $TechWhite;
    background-color: $TechGrayDark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;

    @include mobile-design {
      padding-bottom: 2rem;
    }

    .values__title {
      color: $TechWhite;
      margin-bottom: 4rem;

      @include mobile-design {
        font-size: 2.4rem;
        margin-bottom: 1rem;
        text-align: center;
        max-width: 40rem;
        padding: 0 2rem;
        text-transform: lowercase;
      }
    }

    .values__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile-design {
        flex-direction: column;
      }

      .values__value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-width: 16rem;
        height: 18.2rem;
        margin: 0 4rem;

        @include tablet-design {
          margin: 0 2rem;
          height: 14rem;
        }

        @include mobile-design {
          margin: 1.5rem 0;
        }

        .values__bird {
          width: 15rem;
          height: 15rem;

          @include tablet-design {
            width: 10rem;
            height: 10rem;
          }
        }

        .fontIcon--desktop {
          @include tablet-design {
            display: none;
          }
        }

        .fontIcon--tablet {
          display: none;

          @include tablet-design {
            display: initial;
          }
        }

        .values__text {
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }
    }
  }

  &__mission {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;

    @include mobile-design {
      margin: 2rem 0;
    }

    .mission__title {
      margin-bottom: 2rem;

      @include mobile-design {
        font-size: 3rem;
        margin-bottom: 1rem;
        text-transform: lowercase;
      }
    }

    .mission__text {
      font-size: 2rem;
      font-family: CinzelBold;
      color: $TechPrimary;
      max-width: 70rem;
      text-align: center;
      line-height: 3rem;

      @include mobile-design {
        font-size: 1.6rem;
        font-family: NotoSerif;
        max-width: 50rem;
        width: 92%;
        line-height: 2.4rem;
      }
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    padding: 0 2rem;

    @include tablet-design {
      margin-bottom: 2rem;
    }

    @include mobile-design {
      padding: 0;
    }

    .message__content {
      width: 50rem;
      margin-right: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tablet-design {
        width: 100%;
        margin-right: 0;
        display: initial;
      }

      @include mobile-design {
        display: flex;
      }

      .message__text {
        margin-bottom: 1.6rem;
        text-indent: 3rem;

        @include mobile-design {
          width: 90%;
          max-width: 50rem;
          margin: 1.5rem 0 0;
          line-height: 2.5rem;
        }
      }

      @include mobile-design {
        .message__link {
          margin-top: 2rem;
          width: 90%;
          max-width: 30rem;
        }
      }
    }

    .message__img {
      width: 55rem;
      height: 35rem;
      object-fit: cover;
      border-radius: 12px;

      @include tablet-design {
        width: 45%;
        aspect-ratio: 4/3;
        height: auto;

        &--desktop {
          display: none;
        }
      }

      &--tablet {
        display: none;
        float: right;
        max-width: 50%;
        align-self: flex-end;
        margin: 0 0 3rem 3rem;

        @include tablet-design {
          display: initial;
        }

        @include mobile-design {
          float: none;
          max-width: initial;
          width: 100%;
          height: auto;
          margin: 0 0 0.5rem;
          align-self: normal;
          border-radius: 0;
          aspect-ratio: 16/9;
          max-height: 25rem;
        }
      }
    }
  }
}
